import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

export enum RecommendationModalAction {
	DISMISS,
	RECOMMENDATION,
	CUSTOMIZATION,
}

@Component({
	selector: 'app-recommendation-modal',
	templateUrl: './recommendation-modal.component.html',
	styleUrls: ['recommendation-modal.component.scss'],
})
export class RecommendationModalComponent implements OnInit {
	constructor(private modalCtrl: ModalController) {}

	static async show(modalCtrl: ModalController): Promise<RecommendationModalAction> {
		const modal = await modalCtrl.create({
			component: RecommendationModalComponent,
			cssClass: ['auto-height', 'align-bottom'],
			showBackdrop: true,
		});
		await modal.present();
		const result = (await modal.onDidDismiss()).data;
		return result ? result : RecommendationModalAction.DISMISS;
	}

	async dismiss() {
		await this.modalCtrl.dismiss(RecommendationModalAction.DISMISS);
	}

	async recommendation() {
		await this.modalCtrl.dismiss(RecommendationModalAction.RECOMMENDATION);
	}

	async customization() {
		await this.modalCtrl.dismiss(RecommendationModalAction.CUSTOMIZATION);
	}

	ngOnInit() {}
}
