import { apiEnvironment } from '../smoothr-web-app-core/environments/apiEnvironment';
import 'zone.js/dist/zone-error';

export const ottosburgerCustomerGroup = 'ottosburger';
export const customerGroup = ottosburgerCustomerGroup;

const supportEmail = {
	ottosburger: 'ottosburger@smoothr.de',
};
const firebaseConfig = {
	ottosburger: {
		apiKey: 'AIzaSyDzopA0HXMwlMKLKBu93uzX5y_1EgseEec',
		authDomain: 'dev-env-216009.firebaseapp.com',
		databaseURL: 'https://dev-env-216009.firebaseio.com',
		projectId: 'dev-env-216009',
		storageBucket: 'dev-env-216009.appspot.com',
		messagingSenderId: '1004449889395',
		appId: '1:1004449889395:web:3c4a59d43b865b9be0fe06',
		measurementId: 'G-4QZJNQVQZ3',
	},
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyALu3JlFerOEWzCz8B_pxQXmFFmyk4ZxPQ',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	...apiEnvironment,
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup + '-dev',
	countryList: ['de'],
};
