import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import Address from '../../../smoothr-web-app-core/models/Address';
import { MapsUtils } from '../../../smoothr-web-app-core/utils/maps-utils';
import { HomePopoverComponent } from '../home-popover/home-popover.component';

@Component({
	selector: 'app-menu-toolbar',
	templateUrl: './menu-toolbar.component.html',
	styleUrls: ['menu-toolbar.component.scss'],
})
export class MenuToolbarComponent implements OnInit {
	@Input()
	preorderType = PreorderType.TAKE_AWAY;
	addressText: string;

	constructor(private popoverCtrl: PopoverController) {}

	@Input()
	set address(value: Address) {
		this.addressText = MapsUtils.addressToString(value);
	}

	ngOnInit() {}
	async presentHomePopover(ev: any) {
		console.log('presentHome');
		const popover = await this.popoverCtrl.create({
			component: HomePopoverComponent,
			event: ev,
			translucent: true,
			mode: 'ios',
			cssClass: 'home-popover',
		});
		return await popover.present();
	}
}
