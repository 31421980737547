import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import { MenuPage } from '../menu/menu.page';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import { MatSnackBar } from '@angular/material/snack-bar';
import { venueAcceptsOrders } from '../../../smoothr-web-app-core/utils/utils';
import { venueToAddress } from '../../../smoothr-web-app-core/utils/maps-utils';

@Component({
	selector: 'app-venue',
	templateUrl: './venue.page.html',
	styleUrls: ['venue.page.scss'],
})
export class VenuePage implements OnInit {
	static url = 'store';

	loading = true;
	venues: Venue[];
	selectedVenue: Venue = null;

	constructor(
		private router: Router,
		private repository: RepositoryService,
		private snackbarCtrl: MatSnackBar
	) {}

	static navigate(router: Router) {
		router.navigateByUrl(VenuePage.url);
	}

	ionViewDidEnter() {}

	ngOnInit() {
		this.loadVenues();
	}

	async loadVenues() {
		this.loading = true;
		this.venues = (await this.repository.getAllVenues()).filter(ven => venueAcceptsOrders(ven, PreorderType.TAKE_AWAY));
		this.loading = false;
	}

	async selectVenue(venue: Venue, preorderType: PreorderType, attempt: number = 0, prevError: any = null) {
		if (attempt > 5) {
			this.loading = false;
			console.error(prevError);
			this.snackbarCtrl.open(prevError, null, {
				duration: 2000,
			});
			return;
		}
		this.loading = true;
		try {
			const address = venueToAddress(venue);
			await this.repository.getVenue(venue._id);
			this.repository.createOrder(venue, address, preorderType);
			this.repository.address.emit(address);
			await MenuPage.navigate(this.router);
			this.loading = false;
		} catch (e) {
			await this.selectVenue(venue, preorderType, attempt + 1, e);
		}
	}
}
