import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ArticleOptionGroupFlavorComponent } from '../components/article-option-group-flavor/article-option-group-flavor.component';
import { ItemSelectableArticleComponent } from '../components/item-selectable-article/item-selectable-article.component';
import { OrderListComponent } from '../components/order-list/order-list.component';
import { ToggleButtonGroupComponent } from '../components/toggle-button-group/toggle-button-group.component';
import { MenuPopoverComponent } from '../components/menu-popover/menu-popover.component';
import { FormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MyOrderComponent } from '../components/my-order/my-order.component';
import { QRCodeModule } from 'angular2-qrcode';
import { ItemViewComponent } from '../components/item-view/item-view.component';
import { ArticleOptionGroupSingleComponent } from '../components/article-option-group-single/article-option-group-single.component';
import { VenueSuggestionComponent } from '../components/venue-suggestion/venue-suggestion.component';
import { HomePopoverComponent } from '../components/home-popover/home-popover.component';
import { ArticleOptionGroupCheckboxComponent } from '../components/article-option-group-checkbox/article-option-group-checkbox.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { createTranslateLoader } from '../app.module';
import { BurgerBtnComponent } from '../components/burger-btn/burger-btn.component';
import { ArticleOptionGroupMultipleComponent } from '../components/article-option-group-multiple/article-option-group-multiple.component';
import { RouterModule } from '@angular/router';
import { OrderContentComponent } from '../components/order-content/order-content.component';
import { PromoCodeComponent } from '../components/promo-code/promo-code.component';
import { PaymentMethodsDisplayComponent } from '../components/payment-methods-display/payment-methods-display.component';
import { CustomAddressModalComponent } from '../components/custom-address-modal/custom-address-modal.component';
import { SelectVenueModalComponent } from '../components/select-venue-modal/select-venue-modal.component';
import { TipComponent } from '../components/tip/tip.component';
import { LangStringPipe } from '../../smoothr-web-app-core/pipes/lang-string.pipe';
import { TagComponent } from '../components/tag/tag.component';
import { QuantitySelectorComponent } from '../components/quantity-selector/quantity-selector.component';
import { PreorderTypeChipComponent } from '../components/preorder-type-chip/preorder-type-chip.component';
import { AccountToolbarComponent } from '../components/account-toolbar/account-toolbar.component';
import { MenuToolbarComponent } from '../components/menu-toolbar/menu-toolbar.component';
import { SlotsSelectorComponent } from '../components/slots-selector/slots-selector.component';

@NgModule({
	declarations: [
		ArticleOptionGroupFlavorComponent,
		ArticleOptionGroupSingleComponent,
		ItemSelectableArticleComponent,
		OrderListComponent,
		ToggleButtonGroupComponent,
		MenuPopoverComponent,
		VenueSuggestionComponent,
		MyOrderComponent,
		HomePopoverComponent,
		ItemViewComponent,
		ArticleOptionGroupCheckboxComponent,
		ArticleOptionGroupMultipleComponent,
		BurgerBtnComponent,
		OrderContentComponent,
		PromoCodeComponent,
		PaymentMethodsDisplayComponent,
		CustomAddressModalComponent,
		SelectVenueModalComponent,
		TagComponent,
		TipComponent,
		LangStringPipe,
		QuantitySelectorComponent,
		PreorderTypeChipComponent,
		AccountToolbarComponent,
		MenuToolbarComponent,
		SlotsSelectorComponent,
	],
	exports: [
		ArticleOptionGroupCheckboxComponent,
		ArticleOptionGroupFlavorComponent,
		ArticleOptionGroupSingleComponent,
		ArticleOptionGroupMultipleComponent,
		ItemSelectableArticleComponent,
		OrderListComponent,
		ToggleButtonGroupComponent,
		HomePopoverComponent,
		VenueSuggestionComponent,
		MenuPopoverComponent,
		BurgerBtnComponent,
		MyOrderComponent,
		ItemViewComponent,
		TranslateModule,
		OrderContentComponent,
		PromoCodeComponent,
		CustomAddressModalComponent,
		SelectVenueModalComponent,
		LangStringPipe,
		TagComponent,
		QuantitySelectorComponent,
		PreorderTypeChipComponent,
		AccountToolbarComponent,
		MenuToolbarComponent,
		SlotsSelectorComponent,
	],
	imports: [
		CommonModule,
		IonicModule,
		FormsModule,
		MatSnackBarModule,
		QRCodeModule,
		TranslateModule.forChild({
			defaultLanguage: 'de',
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient],
			},
		}),
		RouterModule,
	],
})
export class SharedModuleModule {}
