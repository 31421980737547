import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import Address from '../../../smoothr-web-app-core/models/Address';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';

export class SelectVenueModalResult {
	constructor(
		public venue: Venue,
		public address: Address,
		public preorderType: PreorderType
	) {}
}

@Component({
	selector: 'app-select-venue-modal',
	templateUrl: './select-venue-modal.component.html',
	styleUrls: ['select-venue-modal.component.scss'],
})
export class SelectVenueModalComponent implements OnInit {
	venues: Venue[] = [];
	loading = true;
	preorderType: PreorderType;
	selectedVenue: Venue = null;
	error: string;

	constructor(
		private repository: RepositoryService,
		private snackBarCtrl: MatSnackBar,
		private modalCtrl: ModalController
	) {}

	static show(modalCtrl: ModalController, preorderType: PreorderType): Promise<SelectVenueModalResult> {
		return new Promise<SelectVenueModalResult>(async resolve => {
			const modal = await modalCtrl.create({
				component: SelectVenueModalComponent,
				cssClass: 'select-venue-modal',
				componentProps: {
					allowGps: false,
					preorderType,
				},
			});
			await modal.present();
			const result: SelectVenueModalResult = (await modal.onDidDismiss()).data;
			resolve(result);
		});
	}

	ngOnInit() {
		this.loadVenues();
	}

	async loadVenues() {
		this.loading = true;
		this.venues = await this.repository.getAllVenues();
		if (this.venues.length <= 0) {
			this.loading = false;
			this.error = 'Keine Restaurants in deiner Nähe';
			return;
		}
		this.selectedVenue = this.venues[0];
		this.loading = false;
	}

	selectVenue(venue: Venue, preorderType: PreorderType) {
		const address = new Address();
		address.street = venue.street;
		address.city = venue.city.de;
		address.number = venue.number;
		address.postalCode = venue.postalCode;
		address.country = venue.country;
		address.lat = venue.location.coordinates[1];
		address.lng = venue.location.coordinates[0];
		console.log('Venue selected in modal', venue.name, address, preorderType);
		this.modalCtrl.dismiss(new SelectVenueModalResult(venue, address, preorderType));
	}
}
